import { NAME as HeaderName } from '@spa-ec/components/Header/Header.theme';
import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as ProductDetailsName } from '@spa-ec/components/ProductDetails/ProductDetails.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as CartName } from '@spa-ec/components/Cart/Cart.theme';
import { NAME as LoginName } from '@spa-ec/components/Login/Login.theme';
import { NAME as StockStatusName } from '@spa-ec/components/StockStatus/StockStatus.theme';
import { NAME as SiteLogoName } from '@spa-ec/components/Header/HeaderSiteLogo/SiteLogo.theme';
import { NAME as ProductTableCategoriesName, } from '@spa-ec/components/ProductTable/ProductTableCategory/ProductTableCategory.theme';
import { NAME as OrderHistoryName } from '@spa-ec/components/OrderHistory/OrderHistory.theme';
import theme from './theme.conf';
const contentPageTheme = {
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
};
const headerTheme = {
    showCountrySelector: false,
};
const productDetailsTheme = {
    gapSeparator: true,
    showCartIcon: true,
    applyPaddingSubscriptionPanel: false,
};
const pageTitleTheme = {
    fg: 'white',
    bg: theme.palette.meBrown,
};
const cartTableTheme = {
    showCartPriceTitle: false,
    showProductUnit: true,
};
const loginTheme = {
    showLoginTitle: false,
};
const productTableCategoryTheme = {
    excludeSameProductFromShelf: true,
};
const cartTheme = {
    showSubscription: true,
    updateShippingString: true,
};
const stockStatusTheme = {
    showIcon: true,
};
const siteLogoTheme = {
    siteLogo: {
        imagesRootPath: '/images/sitelogo',
        paths: {
            b2c: {
                se: {
                    sv: '/minespresso_logo.jpg',
                },
                dk: {
                    da: '/minespresso_logo.jpg',
                },
                fi: {
                    fi: '/minespresso_logo.jpg',
                },
                no: {
                    no: '/minespresso_logo.jpg',
                },
                default: '/minespresso_logo.jpg',
            },
            b2b: {
                default: '/minespresso_logo.jpg',
            },
        },
    },
};
const orderHistoryTheme = {
    showStatusText: false,
};
export default {
    [ContentPageName]: contentPageTheme,
    [HeaderName]: headerTheme,
    [ProductDetailsName]: productDetailsTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [LoginName]: loginTheme,
    [ProductTableCategoriesName]: productTableCategoryTheme,
    [CartName]: cartTheme,
    [StockStatusName]: stockStatusTheme,
    [SiteLogoName]: siteLogoTheme,
    [OrderHistoryName]: orderHistoryTheme,
};
