import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from '@ui-elem/Button/Button'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import Modal from '@ui-elem-js/Modal'
import { t } from '@spa-core/locale'
import { NavLink } from 'react-router-dom'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { useSelector } from 'react-redux'
import TextInput from '@ui-elem/TextInput/TextInput'

type ComponentState = {
    sessionConfig: SessionConfig
    fetchingPersonalizedDeliveryOffers: boolean
    hasError: boolean
}

type Props = {
    openDeliveryInfoPopup: boolean
    setOpenDeliveryInfoPopup: (open: boolean) => void
    personalizedTownCity: string
    fetchDeliveryOptionsForPostalCode: (inputPostcode: string) => void
    personalizedPostCode: string
    scrollUp: () => void
}

const DeliveryInfoPopup: React.FC<Props> = ({
    openDeliveryInfoPopup,
    setOpenDeliveryInfoPopup,
    personalizedTownCity,
    fetchDeliveryOptionsForPostalCode,
    personalizedPostCode,
    scrollUp,
}) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const fetchingPersonalizedDeliveryOffersStore = ({ reducers }: Store) =>
        reducers[appReducerName].fetchingPersonalizedDeliveryOffers
    const personalizedDeliveryOffersStore = ({ reducers }: Store) => reducers[appReducerName].personalizedDeliveryOffers
    const selector = createSelector(
        [sessionConfigStore, fetchingPersonalizedDeliveryOffersStore, personalizedDeliveryOffersStore],
        (sessionConfig, fetchingPersonalizedDeliveryOffers, personalizedDeliveryOffers): ComponentState => ({
            sessionConfig,
            fetchingPersonalizedDeliveryOffers,
            hasError: personalizedDeliveryOffers?.hasError,
        }),
    )
    const { sessionConfig, fetchingPersonalizedDeliveryOffers, hasError }: ComponentState = useSelector(selector)

    useEffect(() => {
        handleClickOutsideRef.current = handleClickOutside
        document.addEventListener('mousedown', handleClickOutsideRef.current)
    }, [])

    const handleClickOutside = (event) => {
        if (componentRef?.current?.contains?.(event.target)) return
        if (!componentRef?.current?.contains?.(event.target)) {
            setInputPostcode('')
            setOpenDeliveryInfoPopup(false)
        }
    }

    const [inputPostcode, setInputPostcode] = useState<string>()
    const [hasFetchedDeliveryOptionsForPostalCode, setHasFetchedDeliveryOptionsForPostalCode] = useState<boolean>(false)
    const componentRef = useRef<any>(null)
    const handleClickOutsideRef = useRef<any>(handleClickOutside)

    const errorMessage: string =
        hasFetchedDeliveryOptionsForPostalCode && inputPostcode && hasError ? t('phrase.invalid.postalcode') : undefined

    const doFetchDeliveryOptionsForPostalCode = useCallback(() => {
        fetchDeliveryOptionsForPostalCode(inputPostcode)
        setHasFetchedDeliveryOptionsForPostalCode(true)
    }, [inputPostcode])

    useEffect(() => {
        if (!hasError && hasFetchedDeliveryOptionsForPostalCode && !fetchingPersonalizedDeliveryOffers) {
            setInputPostcode('')
            setHasFetchedDeliveryOptionsForPostalCode(false)
            setOpenDeliveryInfoPopup(false)
        }
    }, [hasError, hasFetchedDeliveryOptionsForPostalCode, fetchingPersonalizedDeliveryOffers])

    return (
        <Modal
            mode={'dialog'}
            padding={'none'}
            size={'extra-small'}
            open={openDeliveryInfoPopup}
            onClose={() => {
                setHasFetchedDeliveryOptionsForPostalCode(false)
                setOpenDeliveryInfoPopup(false)
            }}
            className={'ic-inkclub-postcode-lookup'}
            closeIconSize={'large'}
            closeIcon={Icons.TimesCircle}
            key={'modal-postcode'}
        >
            <div className="e2e-ver-postcode-lookup-popup p-4 mb-2 col-pale" ref={componentRef}>
                <div className="text-base font-bold text-center flex flex-row justify-center">
                    {t('postalcode-lookup-title')}
                    <Icon className="ml-2 self-center" icon={Icons.MapMarkerAlt} size={13} />
                </div>
                {sessionConfig.userLoggedIn && personalizedPostCode && personalizedTownCity ? (
                    <>
                        <p className="text-center mb-2">{t('postcode.text.paragraph1')}</p>
                        <div className="flex justify-center">
                            <div className="col-panel p-2 mb-2 w-1/2">
                                <p className="text-center items-center">
                                    {personalizedPostCode && personalizedTownCity
                                        ? personalizedPostCode + ' ' + personalizedTownCity
                                        : t('checkout.form.postcode.invalid.empty')}
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <p className="text-center mb-2">{t('postcode.text.hintlogin')}</p>
                        <div className="flex justify-center">
                            <NavLink to={'/my-account'} activeClassName="active">
                                <Button
                                    onClick={() => setOpenDeliveryInfoPopup(false)}
                                    className="e2e-ver-postcode-login-button px-4 py-2"
                                    buttonText={t('login.login')}
                                />
                            </NavLink>
                        </div>
                    </>
                )}
                <div className="text-center">
                    <p>{t('postcode.text.paragraph2')}</p>
                </div>
                <div className="pt-4 flex justify-center">
                    <TextInput
                        onKeyUp={(e) => {
                            if (e.key === 'Enter' && inputPostcode.length === 5) {
                                doFetchDeliveryOptionsForPostalCode()
                            } else if (!/[0-9]/.test(e.key)) {
                                e.preventDefault()
                            }
                        }}
                        value={inputPostcode}
                        className="e2e-ver-postcode-input ml-2 w-1/2 border"
                        placeholder={t('postcode.input.placeholder')}
                        maxLength={5}
                        name="postCode"
                        id="postCode"
                        onChange={(e) => setInputPostcode(e.target.value)}
                        errorMessage={errorMessage}
                        disabled={fetchingPersonalizedDeliveryOffers}
                        formSubmitted
                    />
                    <Button
                        onClick={() => {
                            if (inputPostcode?.length === 5) {
                                doFetchDeliveryOptionsForPostalCode()
                                scrollUp()
                            }
                        }}
                        className="e2e-ver-postcode-search px-6 py-2 ml-2"
                        buttonText={t('text.button.choose')}
                        loading={fetchingPersonalizedDeliveryOffers}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DeliveryInfoPopup
