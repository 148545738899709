export const NAME: string = 'ColumnCard'

/**
 * TODO: Layout is candidate for removal
 */
export type ColumnCardTheme = {
    layout?: ColumnCartLayout
    alignHeaderPreambleLeft?: boolean
    descriptionTextSize?: 'text-sm' | 'text-base'
}

export enum ColumnCartLayout {
    SINGLE_COLUMN = 'singleColumn',
    DOUBLE_COLUMN = 'doubleColumn',
}
