import React from 'react'
import SVG from 'react-inlinesvg'
import classNames from 'classnames'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    iconType: string
    imageUrl: string
    className?: string
}

const ImageIcon: React.FC<Props> = ({ iconType, imageUrl, className }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[sessionReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    let SvgImage: string
    let normalImage: string

    switch (iconType) {
        case 'dustbag':
            SvgImage = `${sessionConfig.contextPath}/_ui/dist/static/images/Damm-bag-black.svg`
            break
        case 'alternateDustbag':
            normalImage = `${sessionConfig.contextPath}/_ui/dist/static/images/icon-vacuum_cleaner.svg`
            break
        case 'cart-add':
            SvgImage = `${sessionConfig.contextPath}/_ui/dist/static/images/refillexpress-cart.svg`
            break
        case 'printer':
            normalImage = `${sessionConfig.contextPath}/_ui/dist/static/images/icon-printer.png`
            break
        case 'category-image':
            normalImage = imageUrl
            break
        default:
    }

    return normalImage === '' || normalImage === undefined ? (
        <SVG className={classNames('fill-white stroke-white svg-icon', className)} src={SvgImage} />
    ) : (
        <img className={classNames('fill-white stroke-white', className)} src={normalImage} alt="Image icon" />
    )
}

export default ImageIcon
