import React, { KeyboardEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { getComponentTheme } from '@ic-theme'
import { Icons } from '@ui-elem/Icon/Icon'
import Button, { ButtonColors, IconAlignments } from '@ui-elem/Button/Button'
import { NAME, QuantityCounterTheme } from './QuantityCounter.theme'
import debounce from '@spa-core/util/debounce'

const theme: QuantityCounterTheme = getComponentTheme<QuantityCounterTheme>(NAME, {
    maxQuantity: 100,
})

export const MAX_QUANTITY = theme.maxQuantity
export const MIN_QUANTITY = 1

let newQuantity: number

interface Props {
    onDecrease: (quantity: number) => void
    onIncrease: (quantity: number) => void
    setCount: (quantity: number, isCapped?: boolean) => void
    count: number
    readOnly?: boolean
    allowZeroQuantity?: boolean
    onQuantityChangeDebounce?: number
}

const QuantityCounter = ({
    onDecrease,
    onIncrease,
    setCount,
    count,
    allowZeroQuantity,
    readOnly = false,
    onQuantityChangeDebounce = 50,
}: Props) => {
    const validateAndSetCount = useCallback((quantity: string = '') => {
        const newQuantity: number = parseInt(quantity, 10)
        if (typeof newQuantity === 'number' && newQuantity <= MAX_QUANTITY) {
            setCount(newQuantity)
            setDisplayCount(`${newQuantity}`)
        }
    }, [])

    const [displayCount, setDisplayCount] = useState<string>(`${count}`)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        newQuantity = count
    }, [])

    const debounceOnIncrease = debounce(() => {
        onIncrease(newQuantity)
    }, onQuantityChangeDebounce)

    const debounceOnDecrease = debounce(() => {
        onDecrease(newQuantity)
    }, onQuantityChangeDebounce)

    return (
        <div className="flex flex-row gap-1 justify-center">
            <Button
                className="p-2 quantityCounterButton size-10"
                buttonColor={ButtonColors.SECONDARY}
                buttonIcon={Icons.Minus}
                iconAlignment={IconAlignments.CENTER}
                onClick={(e: MouseEvent) => {
                    newQuantity = parseInt(displayCount, 10) - 1
                    if (newQuantity >= MIN_QUANTITY || (allowZeroQuantity && newQuantity === 0)) {
                        setDisplayCount(`${newQuantity}`)
                        debounceOnDecrease()
                    }
                }}
                buttonPadding=""
            />
            <input
                className="w-1/4 text-center quantityCounterInput"
                readOnly={readOnly}
                value={displayCount}
                ref={inputRef}
                onChange={() => {}}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    e.preventDefault()
                    const allSelected: boolean =
                        inputRef.current.selectionStart === 0 && inputRef.current.selectionEnd === inputRef.current.value.length
                    const someSelected: boolean = inputRef.current.selectionStart !== inputRef.current.selectionEnd
                    const currentValue: string = inputRef.current.value
                    if (e?.key === 'Backspace') {
                        if (allSelected) {
                            setDisplayCount('')
                        } else if (someSelected) {
                            validateAndSetCount(
                                currentValue.slice(inputRef.current.selectionStart, inputRef.current.selectionEnd),
                            )
                        } else if (currentValue.length - 1 > 0) {
                            const currentValueNumbers: string[] = [...currentValue]
                            const newValue: number = parseInt(
                                currentValueNumbers
                                    .filter((_: string, index: number) => index !== inputRef.current.selectionStart - 1)
                                    .join(''),
                                10,
                            )
                            if (newValue > 0) {
                                validateAndSetCount(`${newValue}`)
                            } else {
                                setDisplayCount('')
                            }
                        } else {
                            setDisplayCount('')
                        }
                        return
                    } else if (e?.key === 'Delete') {
                        setDisplayCount('')
                        return
                    } else if (allSelected) {
                        validateAndSetCount(`${e.key}`)
                        return
                    } else if (someSelected) {
                        const currentValueNumbers: string[] = [...currentValue]
                        const newValue: string[] =
                            inputRef.current.selectionStart !== 0
                                ? currentValueNumbers.slice(0, inputRef.current.selectionStart)
                                : []
                        newValue.push(e.key)
                        newValue.push(...currentValueNumbers.slice(inputRef.current.selectionEnd))
                        validateAndSetCount(newValue.join(''))
                        inputRef.current.selectionStart = inputRef.current.selectionEnd
                    } else {
                        validateAndSetCount(`${currentValue}${e.key}`)
                    }
                }}
                onBlur={(e) => {
                    e.preventDefault()
                    const newQuantity: number = parseInt(inputRef.current.value || `${count}`, 10)
                    if (typeof newQuantity === 'number' && newQuantity > 0) {
                        validateAndSetCount(`${newQuantity}`)
                    } else {
                        validateAndSetCount('1')
                    }
                }}
            />
            <Button
                className="p-2 quantityCounterButton size-10"
                buttonColor={ButtonColors.SECONDARY}
                buttonIcon={Icons.Plus}
                iconAlignment={IconAlignments.CENTER}
                onClick={() => {
                    newQuantity = parseInt(displayCount, 10) + 1
                    if (newQuantity <= MAX_QUANTITY) {
                        setDisplayCount(`${newQuantity}`)
                        debounceOnIncrease()
                    }
                }}
                buttonPadding=""
            />
        </div>
    )
}

export default QuantityCounter
