import React, { MouseEventHandler } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { t } from '@spa-core/locale'
import Button from '@ui-elem/Button/Button'
import { SlimProduct } from '@spa-core/store/products/interfaces'
import { ProductAvailability, ProductOneBuyStatus } from '@spa-core/store/products/constants'
import { Store } from '@spa-core/store'
import { NAME as cartReducerName } from '@spa-core/store/cart/constants'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import classNames from 'classnames'

type ComponentState = {
    sessionConfig: SessionConfig
    isSpinning: boolean
}

enum Styles {
    CARD_BUY_BUTTON_CONFIG = 'card_buy_button_config',
}

type Props = {
    slimProduct: SlimProduct
    subscriptionIsOpen: boolean
    subscriptionButtonHandler: MouseEventHandler
    buyButtonHandler: (currentlyAddingKey: string) => void
    oneBuyStatus: ProductOneBuyStatus
}

const CardButtons: React.FC<Props> = ({
    slimProduct,
    subscriptionIsOpen,
    subscriptionButtonHandler,
    buyButtonHandler,
    oneBuyStatus,
}) => {
    const currentlyAddingKey: string = `${slimProduct.code}${oneBuyStatus !== ProductOneBuyStatus.NONE ? '_enKrona' : ''}`
    const currentlyAddingToCart = ({ reducers }: Store) => reducers[cartReducerName].currentlyAddingToCart
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [currentlyAddingToCart, sessionConfigStore],
        (currentlyAddingToCart, sessionConfig): ComponentState => ({
            isSpinning: currentlyAddingToCart[currentlyAddingKey],
            sessionConfig,
        }),
    )
    const { isSpinning, sessionConfig }: ComponentState = useSelector(selector)

    return (
        <div className={'min-h-10 md:min-h-6 w-full flex flex-col md:flex-row justify-end lg:justify-center lg:gap-2'}>
            {/* Subscription Button */}
            {/* Special exception for minespresso, due to its special subscription setup */}
            {/**
             * TODO: Remove minespresso check once the site is removed from build pipeline
             */}
            {slimProduct.subscriptionIsAvailable && sessionConfig.siteName !== 'MINespresso' ? (
                <Button
                    loading={subscriptionIsOpen}
                    onClick={subscriptionButtonHandler}
                    disabled={
                        oneBuyStatus === ProductOneBuyStatus.DISABLED ||
                        slimProduct.stockStatus?.availability === ProductAvailability.NOT_BUYABLE
                    }
                    className="w-full md:my-2 mb-1 text-sm whitespace-nowrap e2e-ver-card-subscription-button"
                    buttonPadding={'p-2 md:py-2 md:px-4'}
                    buttonText={subscriptionIsOpen ? '' : t('product.subscribe')}
                />
            ) : null}

            {/* Buy Button */}
            <Button
                loading={isSpinning}
                onClick={() => buyButtonHandler(currentlyAddingKey)}
                disabled={
                    isSpinning ||
                    oneBuyStatus === ProductOneBuyStatus.DISABLED ||
                    slimProduct.stockStatus?.availability === ProductAvailability.NOT_BUYABLE
                }
                className={classNames(
                    'my-0 lg:my-2 text-sm whitespace-nowrap e2e-ver-card-buy-button',
                    Styles.CARD_BUY_BUTTON_CONFIG,
                )}
                buttonPadding={'p-2 md:py-2 md:px-4'}
                buttonText={t('basket.add.to.basket')}
            />
        </div>
    )
}

export default CardButtons
