import classNames from 'classnames'
import React from 'react'

export enum DeckColumnsDesktop {
    COLUMNS_NONE = 'md:grid-cols-none',
    COLUMNS_1 = 'md:grid-cols-1',
    COLUMNS_2 = 'md:grid-cols-2',
    COLUMNS_3 = 'md:grid-cols-3',
    COLUMNS_4 = 'md:grid-cols-4',
    COLUMNS_5 = 'md:grid-cols-5',
    COLUMNS_6 = 'md:grid-cols-6',
    COLUMNS_7 = 'md:grid-cols-7',
    COLUMNS_8 = 'md:grid-cols-8',
    COLUMNS_9 = 'md:grid-cols-9',
    COLUMNS_10 = 'md:grid-cols-10',
    COLUMNS_11 = 'md:grid-cols-11',
    COLUMNS_12 = 'md:grid-cols-12',
}
export enum DeckColumnsMobile {
    COLUMNS_NONE = 'grid-cols-none',
    COLUMNS_1 = 'grid-cols-1',
    COLUMNS_2 = 'grid-cols-2',
    COLUMNS_3 = 'grid-cols-3',
}

export enum DeckGap {
    GAP_1 = 'gap-1',
    GAP_2 = 'gap-2',
    GAP_3 = 'gap-3',
    GAP_4 = 'gap-4',
    GAP_5 = 'gap-5',
    GAP_6 = 'gap-6',
    GAP_7 = 'gap-7',
    GAP_8 = 'gap-8',
}
type Props = {
    children?: React.ReactNode
    deckColumnsDesktop: DeckColumnsDesktop
    deckColumnsMobile: DeckColumnsMobile
    deckGap: DeckGap
    classes?: string
}

const Deck: React.FC<Props> = ({ children, deckColumnsDesktop, deckColumnsMobile, deckGap, classes }) => {
    if (!children) return null

    return <div className={classNames('grid', deckColumnsDesktop, deckColumnsMobile, deckGap, classes)}>{children}</div>
}

export default Deck
