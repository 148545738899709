import React from 'react'
import classnames from 'classnames'

enum Styles {
    CARD_PRICE_TEXT = 'card_price_text',
    CARD_DISCOUNTED_PRICE_TEXT = 'card_discounted_price_text',
    CARD_PRICE_DISCOUNT_ACTIVE = 'card_price_discount_active',
}

type Props = {
    price: string
    formattedDiscountedPrice: string
    hasDiscount: boolean
}

const CardProductPrice: React.FC<Props> = ({ price, formattedDiscountedPrice, hasDiscount }) => (
    <div className="m-auto">
        {hasDiscount ? (
            <div className="flex flex-wrap justify-center">
                <span className={`${Styles.CARD_DISCOUNTED_PRICE_TEXT} text-nowrap pr-1`}>{price}</span>
                <span className={classnames('font-bold text-lg text-nowrap', Styles.CARD_PRICE_DISCOUNT_ACTIVE)}>
                    {formattedDiscountedPrice}
                </span>
            </div>
        ) : (
            <div className={`font-bold text-lg mt-5 lg:mt-0 ${Styles.CARD_PRICE_TEXT}`}>{price}</div>
        )}
    </div>
)

export default CardProductPrice
