import React from 'react'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getComponentTheme } from '@ic-theme'
import { StickerTheme, NAME } from './Sticker.theme'
import classNames from 'classnames'

export enum StickerPosition {
    TOP_LEFT = 'top_left',
    TOP_RIGHT = 'top_right',
    BOTTOM_LEFT = 'bottom_left',
    BOTTOM_RIGHT = 'bottom_right',
}
type Props = {
    stickerPosition: StickerPosition
    className?: string
}

type ComponentState = {
    sessionConfig: SessionConfig
}

const theme: StickerTheme = getComponentTheme<StickerTheme>(NAME, {
    showOneKronaSticker: false,
    stickers: {
        se: '',
        default: '',
    },
})

const Sticker: React.FC<Props> = ({ stickerPosition, className = '' }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)
    const currentCountry = sessionConfig.countryIso ? sessionConfig.countryIso.toLowerCase() : ''

    return (
        <img
            alt="Special offer sticker image"
            src={`${sessionConfig.themeResourcePath}/images/icons/${theme.stickers[currentCountry] ? theme.stickers[currentCountry] : theme.stickers.default}`}
            className={classNames(
                'absolute w-2/5',
                className,
                stickerPosition === StickerPosition.TOP_LEFT ? 'top-0 left-0' : '',
                stickerPosition === StickerPosition.TOP_RIGHT ? 'top-0 right-0' : '',
                stickerPosition === StickerPosition.BOTTOM_LEFT ? 'bottom-0 left-0' : '',
                stickerPosition === StickerPosition.BOTTOM_RIGHT ? 'bottom-0 right-0' : '',
            )}
        />
    )
}

export default Sticker
