import React, { useEffect, useState } from 'react'
import { t } from '@spa-core/locale'
import Link from '@ui-elem/Link'
import Button, { ButtonColors } from '@ui-elem/Button/Button'
import { PersonalizedProduct } from '@spa-core/store/app/interfaces'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import Sticker, { StickerPosition } from '@spa-ec/components/Sticker/Sticker'

type ComponentState = {
    sessionConfig: SessionConfig
}

enum Styles {
    OFFER_BANNER_COLOR = 'offer_banner_color',
}

type Props = {
    personalizedProduct: PersonalizedProduct
}

const EnKrOffer: React.FC<Props> = ({ personalizedProduct }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[sessionReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const [resolvedOfferImage, setResolvedOfferImage] = useState<string>()

    useEffect(() => {
        personalizedProduct.typeCode === 't1'
            ? setResolvedOfferImage(`${sessionConfig.themeResourcePath}/images/1kr-banner-printer.png`)
            : null
        personalizedProduct.typeCode === 't5'
            ? setResolvedOfferImage(`${sessionConfig.themeResourcePath}/images/1kr-banner-dustbags.png`)
            : null
    }, [personalizedProduct, setResolvedOfferImage, sessionConfig])

    return (
        <div className={`${Styles.OFFER_BANNER_COLOR} mb-1 md:w-1/2`}>
            <div className="flex flex-row w-full px-4 pt-2 md:pt-0 mb-1">
                <div className="e2e-left-col justify-around flex flex-col w-3/5 md:w-2/3 mt-0 md:mt-2">
                    <div className="text-2xl md:text-3xl font-bold font-spec mb-2">{personalizedProduct.description}</div>
                    <Link to={personalizedProduct.url} key={personalizedProduct.code} title={t('button.text.clickHere')}>
                        <Button
                            buttonColor={ButtonColors.SECONDARY}
                            buttonPadding={'px-10 py-2'}
                            buttonText={t('button.text.clickHere')}
                        />
                    </Link>
                </div>
                <div className="flex flex-col w-2/5 md:w-1/3 text-right relative ">
                    <img
                        src={resolvedOfferImage}
                        alt="Special offer image"
                        className="w-32 md:w-36 absolute top-16 md:top-6 right-2"
                    />
                    {personalizedProduct.isEnkrona ? (
                        <Sticker
                            stickerPosition={StickerPosition.TOP_RIGHT}
                            className="!w-16 !md:w-12 top-8 md:top-2 right-1 md:right-2"
                        />
                    ) : null}
                </div>
            </div>
            <div className="flex flex-row text-sm my-1 w-full px-4 py-2">
                {personalizedProduct.typeCode === 't1' ? t('phrase.1kr.disclaimer.ink') : null}
                {personalizedProduct.typeCode === 't5' ? t('phrase.1kr.disclaimer.dustbag') : null}
            </div>
        </div>
    )
}

export default EnKrOffer
