import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import cloneDeep from 'fast-clone';
import {
  superConnect,
  setupInitialState,
  mapItems,
  setupMutations,
  mapGetters,
  mapActions,
  mergeInitialState,
} from 'core/util/module-utils';
import { getters as prodSvcGetters, actions as prodSvcActions, loadStatus } from '../../services/productsSvc/productsSvcMod';
import find from 'lodash/find';
import { Metadata } from '@spa-ec/components/Head/Head';
import Button, { IconAlignments } from '@ui-elem/Button/Button';
import stripHtmlTags from 'core/util/htmlTags';
import QuantityCounter from '@spa-ec/components/QuantityCounter/QuantityCounter';
import MEProductTitleBar from 'ic/sites/minespresso/components/MEProductTitleBar';
import { t, formatPrice, PriceFormat } from '@spa-core/locale';
import { Pie } from 'deco/ProductImage/ProductImage';
import StockStatus from '@spa-ec/components/StockStatus/StockStatus';
import { compileSeoDataFromProduct } from 'deco/StructuredSEOData';
import CleanText from '@ui-elem/CleanText/CleanText';
import GhostCardPDP from 'eco/ProductDetails/GhostCardPDP';
import Images from '@spa-ec/components/ProductDetails/Images/Images';
import { MatomoLevel, TrackingActionTypes } from '@spa-core/tracking/constants';
import { ErrorTypes, SpaError } from '@spa-ec/components/Error/Error';
import { Icons } from '@ui-elem/Icon/Icon';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';
import { NAME as cartReducerName } from '@spa-core/store/cart/constants';
import { NAME as productsReducerName } from '@spa-core/store/products/constants';
import { addToCart } from '@spa-core/store/cart/actions';
import { fetchProductByCode } from '@spa-core/store/products/actions';
import { MIN_QUANTITY, MAX_QUANTITY } from '@spa-ec-js/components/QuantityCounter/QuantityCounter';
import { setPurchaseQuantity } from '@spa-core/store/app/actions';

// Setup module multi/single instance name etc
const multipleInstances = true;
const name = 'ProductDetail';

// Mudules data, this is the initial data for an instance
const initialState = mergeInitialState({
  galleryOpen: false,
});

const conf = { multipleInstances, name, initialState };

// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  mapGetters(prodSvcGetters(state, ownProps), instance, 'productSvc', [
    'metadata',
    'fetchingSingleProductActive',
    'loadStatusDb',
  ]);

  /**
   * Refactoring mapping
   */
  const sessionConfig = state.reducers[appReducerName]?.sessionConfig;
  instance.sessionConfig = sessionConfig;
  instance.oneBuyUserGroupMap = sessionConfig?.oneBuyUserGroupMap;

  instance.currentlyAddingToCart = state?.reducers?.[cartReducerName]?.currentlyAddingToCart;
  instance.products = state?.reducers?.[productsReducerName]?.products;
  instance.purchaseQuantity = state?.reducers[appReducerName]?.purchaseQuantity;

  return instance;
};

// ################# ACTIONS  #################
export const actions = (dispatch, ownProps) => {
  const self = {
    selectImg: (pos) => dispatch({ type: 'ME_PDET_SELECT_IMG', pos }),
    clearSelection: (pos) =>
      dispatch({ type: 'ME_PDET_CLEAR_SELECTION', matomoLevel: MatomoLevel.MEDIUM, noAnalytics: true, pos }),
    openGallery: (productCode) => dispatch({ type: 'ME_PDET_OPEN_GALLERY', iid: ownProps.iid, noAnalytics: true, productCode }),
    closeGallery: (productCode) => dispatch({ type: 'ME_PDET_CLOSE_GALLERY', iid: ownProps.iid, noAnalytics: true, productCode }),
    reportViewItem: (product) => {
      const productPrice = product.price;
      const discountedPrice = product.discountedPrice ? parseFloat(product.discountedPrice) : 0;
      const discount = discountedPrice ? productPrice - discountedPrice : 0;

      dispatch({
        type: TrackingActionTypes.VIEWED_PRODUCT,
        payload: {
          currency: product.currency,
          value: productPrice - discount,
          discount,
          items: [
            {
              item_id: product.code,
              item_name: product.name,
              price: productPrice,
              b2bStore: getReducer(appReducerName).sessionConfig?.b2bMode,
              quantity: 1,
              discount,
            },
          ],
        },
      });
    },
    quantityAddToCart: (quantity, productCode) => dispatch(addToCart({ quantity, productCode })),
    fetchProductByCode: (payload) => dispatch(fetchProductByCode(payload)),
    incrementQuantity: (newQuantity, productCode) => {
      if (newQuantity <= MAX_QUANTITY) {
        dispatch(setPurchaseQuantity(productCode, newQuantity));
      }
    },
    decrementQuantity: (newQuantity, productCode) => {
      if (newQuantity >= MIN_QUANTITY) {
        dispatch(setPurchaseQuantity(productCode, newQuantity));
      }
    },
    act: {
      ...mapActions(prodSvcActions(dispatch, ownProps), 'productSvc', ['fetchSingleProd']),
    },
  };
  return self;
};

// ################# MUTATIONS  #################

const mutations = {
  ME_PDET_SELECT_IMG: (state, action) => {
    state.selectedImg = action.pos;
  },
  ME_PDET_CLEAR_SELECTION: (state) => {
    state.selectedImg = 0;
  },
  ME_PDET_OPEN_GALLERY: (state, _action) => {
    state.galleryOpen = true;
  },
  ME_PDET_CLOSE_GALLERY: (state, _action) => {
    state.galleryOpen = false;
  },
};

// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name,
  state: setupInitialState(initialState, conf),
  actions,
  getters,
  mutations: setupMutations(mutations, conf),
};

const Content = styled.div`
  &&& {
    background-color: rgb(243, 243, 243);
    color: ${(p) => p.theme.textColor};

    circle {
      stroke: ${(p) => p.theme.textColor};
    }

    .pieFill path {
      fill: ${(p) => p.theme.textColor};
    }

    .quantityCounter {
      width: 100%;
      height: 46px;
      margin-left: 0;
      a {
        box-sizing: border-box;
        height: 38px;
        width: 33%;
        text-align: center;
        border-color: ${(p) => p.theme.col.primary.fg} !important;
        background: ${(p) => p.theme.col.primary.fg};

        span {
          margin-top: 5px;
          width: 100% !important;
          color: white !important;
        }
      }

      input.txtQty {
        width: 34% !important;
        box-sizing: border-box;
        height: 38px;
        font-family: 'Roboto', Arial, sans-serif;
      }
    }
  }
`;

const PieContainer = styled.div`
  ${(props) => (props.val ? '' : 'visibility: hidden')};
`;

const ImageContainer = styled.div`
  &&& {
    img {
      height: 100%;
      width: 100%;
    }
  }
`;

const PurchaseBoxContainer = styled.div`
  &&& {
    text-align: right;
    float: right;
    .purchase-box {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        flex-direction: row;
      }
    }
    .addToCartButton {
      height: 38px;
    }
  }
`;

const Discount = styled.div`
  color: ${(p) => p.theme.col.vars.priceDiscountActive};
`;

const StrikeThrough = styled.div`
  text-decoration: line-through;
  color: ${(p) => p.theme.col.vars.priceDiscountOverruled};
  margin-right: ${(p) => p.theme.space / 2}rem;
`;

const Info = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    margin-top: ${(p) => p.theme.space / 2}rem;
  }
`;
const exctractFeature = (arr, code, defaultValue) => {
  const val = find(arr, { code });
  if (val) {
    return val.featureValues[0].value;
  }

  return defaultValue;
};

const isEnKronaConsumed = (obj, key) => {
  if (!obj) {
    return false;
  }
  if (obj[key]) {
    return obj[key] === true;
  }
};

const Classifications = (p) => (
  <div className={'flex w-4/5 flex-row justify-between pieFill'}>
    {p.strength ? (
      <PieContainer className="flex flex-col items-center" val={p.strength}>
        <Pie value={p.strength / 12} />
        <div>{t('coffee.intensity')}</div>
      </PieContainer>
    ) : null}
    {p.acidity ? (
      <PieContainer className="flex flex-col items-center" val={p.acidity}>
        <Pie value={p.acidity / 12} />
        <div>{t('coffee.acidity')}</div>
      </PieContainer>
    ) : null}
    {p.body ? (
      <PieContainer className="flex flex-col items-center" val={p.body}>
        <Pie value={p.body / 12} />
        <div>{t('coffee.body')}</div>
      </PieContainer>
    ) : null}
    {p.roasting ? (
      <PieContainer className="flex flex-col items-center" val={p.roasting}>
        <Pie value={p.roasting / 12} />
        <div>{t('coffee.roasting')}</div>
      </PieContainer>
    ) : null}
  </div>
);

const IsTrialProductTrueSection = (p) => {
  const quantity = p.purchaseQuantity?.[p.product.code] || MIN_QUANTITY;
  return (
    <div>
      {p.product.discountedPrice && p.product.discountedPrice < p.product.price ? (
        <Info>
          <StrikeThrough className={'text-x1 font-bold text-center'}>{formatPrice(p.product.price)}</StrikeThrough>
          <Discount className={'text-x1 font-bold text-center'}>{formatPrice(p.product.discountedPrice)}</Discount>
        </Info>
      ) : (
        <div className={'text-x1 font-bold text-center'}>{formatPrice(p.product.price)}</div>
      )}

      {p.product.unitsPerPackage > 1 ? (
        <div className={'ic-font-smaller text-center'}>
          {' '}
          {p.product.discountedPrice && p.product.discountedPrice < p.product.price ? (
            <Info>
              {'('}
              {p.product.unitsPerPackage} x{' '}
              <StrikeThrough> {formatPrice(p.product.price / p.product.unitsPerPackage, PriceFormat.DECIMAL)}</StrikeThrough>{' '}
              <Discount> {formatPrice(p.product.discountedPrice / p.product.unitsPerPackage, PriceFormat.DECIMAL)}</Discount>
              {')'}
            </Info>
          ) : (
            <>
              {p.product.unitsPerPackage} x {formatPrice(p.product.price / p.product.unitsPerPackage)}{' '}
            </>
          )}
        </div>
      ) : null}

      <div className={'flex flex-col'}>
        <QuantityCounter
          onDecrease={() => p.decrementQuantity(quantity - 1, p.product.code)}
          onIncrease={() => p.incrementQuantity(quantity + 1, p.product.code)}
          count={quantity}
          setCount={p.setCount}
          key={p.product.code}
        />
        <div className={'my-2'}>
          <Button
            className={'addToCartButton w-full'}
            fluid={true}
            buttonIcon={Icons.ShoppingBasket}
            iconAlignment={IconAlignments.LEFT}
            loading={p.spinning}
            uppercase={true}
            onClick={() => {
              p.quantityAddToCart(quantity, p.product.code);
            }}
            disabled={p.spinning || (p.product.stockStatus && p.product.stockStatus.availability === 'NOT_BUYABLE')}
            buttonText={t('basket.add.to.basket')}
          />
        </div>
      </div>
    </div>
  );
};

const PurchaseBox = (p) => {
  const hiddenClass = p.product.code ? '' : 'hidden';
  return (
    <PurchaseBoxContainer className={'pt-8 ic-col-10 ic-col_sm-12 right-text'}>
      <div className={classnames('ic-grid-column-noGutter purchase-box', hiddenClass)}>
        <div className={'ic-col'}>
          {!p.product.isTrialProduct ? (
            <IsTrialProductTrueSection {...p} />
          ) : (
            <Button
              className="oneBuyAddToCartButton p-4 w-full"
              disabled={p.spinning || isEnKronaConsumed(p.oneBuyUserGroupMap, p.product.oneBuyUserGroupId)}
              fluid={true}
              uppercase={true}
              onClick={() => {
                p.quantityAddToCart(p.count, p.product.code, undefined, false, true);
              }}
              loading={p.spinning}
              buttonText={t('cart.restore.accept')}
            />
          )}
        </div>
      </div>
      <div className={'flex justify-center'}>
        <StockStatus
          availability={p.product.stockStatus?.availability || null}
          stockStatusDescription={p.product.stockStatus?.stockStatusDescription || null}
        />
      </div>
    </PurchaseBoxContainer>
  );
};

const ClassificationsAndCleanTextSection = (p) => {
  const product = p.product;
  return (
    <div className="flex md:w-2/5 md:m-4">
      <div className={'flex flex-col w-full items-center'}>
        {product.strength || product.acidity || product.body || product.roasting ? <Classifications {...product} /> : null}
        <div className={'flex flex-row w-full'}>
          <p dangerouslySetInnerHTML={{ __html: product.description }} />
        </div>
      </div>
    </div>
  );
};

// ################# RENDER  #################
let hasReported = {};

class ProductDetails extends React.Component {
  componentDidMount() {
    if (!this.props.products?.[this.props.productCode]) {
      this.props.fetchProductByCode({ productCode: this.props.productCode, supressProductNotFoundError: true });
    }
  }
  componentWillUnmount() {
    const p = this.props;
    hasReported[p.productCode] = false;
  }
  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const p = this.props;
    const product = p.products?.[this.props.productCode];
    if (!product) return;
    let spinning = p.currentlyAddingToCart[p.productCode];
    const coffee = find(product.classifications, {
      code: 'coffee',
    });
    const allowedTags = ['strong', 'br', 'em', 'p'];
    if (coffee) {
      product.strength = exctractFeature(coffee.features, 'InkclubClassification/1.0/coffee.strength', undefined);
      product.acidity = exctractFeature(coffee.features, 'InkclubClassification/1.0/coffee.acidity', undefined);
      product.body = exctractFeature(coffee.features, 'InkclubClassification/1.0/coffee.body', undefined);
      product.roasting = exctractFeature(coffee.features, 'InkclubClassification/1.0/coffee.roastingvalue', undefined);
    }
    const seoData = compileSeoDataFromProduct(product);

    if (product.code !== '') {
      if (!hasReported[product.code]) {
        hasReported[product.code] = true;
        setTimeout(() => {
          // Defer while we wait for gtm to be setup
          p.reportViewItem(product);
        }, 0);
      }

      return (
        <>
          <Metadata
            metadata={p.productSvc.metadata}
            // meta-title and page title should be same for PDP
            title={product.metaTitle ? product.metaTitle : product.title}
            metaTitle={product.metaTitle}
            metaDescription={product.metaDescription ? product.metaDescription : stripHtmlTags(product.summary)}
            keywords={product.keywords}
            appendSiteName={true}
            seoData={seoData}
          />
          <MEProductTitleBar color={product.code} value={product.strength} name={product.name} code={product.code} />
          <Content className={'flex flex-col-reverse md:flex-row justify-between'}>
            <ClassificationsAndCleanTextSection product={product} allowedTags={allowedTags} />
            <div className={'flex flex-col md:flex-row w-full lg:w-2/3'}>
              <ImageContainer className={'flex items-center justify-center w-full lg:w-2/3'} code={product.code}>
                {product.images ? (
                  <Images
                    selectImg={p.selectImg}
                    selectedImg={p.selectedImg}
                    product={product}
                    closeGallery={p.closeGallery}
                    openGallery={p.openGallery}
                    galleryOpen={p.galleryOpen}
                    add={p.add}
                    manufacturer={product.manufacturer}
                    altText={product.name}
                  />
                ) : null}
              </ImageContainer>
              <div className={'flex items-center justify-center md:mx-12'}>
                <PurchaseBox {...p} product={product} spinning={spinning} count={p.count} key={product.code} />
              </div>
            </div>
          </Content>
        </>
      );
    }
    if (p.productSvc.loadStatusDb[p.productCode] === loadStatus.NOT_FOUND) {
      throw new SpaError({ errorType: ErrorTypes.NOT_FOUND, pageId: p.pageId });
    }
    if (p.productSvc.loadStatusDb[p.productCode] === loadStatus.FETCHING) {
      return (
        <div className={'w-full'}>
          <GhostCardPDP />
        </div>
      );
    }
    return null;
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default superConnect(_module, ProductDetails);
