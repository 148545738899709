import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { t } from '@spa-core/locale'
import classNames from 'classnames'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import SlideInOut from '@ui-elem-js/SlideInOut'
import DeliveryInfoPopup from '@spa-ec/components/PersonalizedCockpit/DeliveryInfo/DeliveryInfoPopup'
import { fetchPersonalizedDeliveryOptions } from '@spa-core/store/app/actions'
import { DeliveryOfferTransporter, AccountProfile, PersonalizedDeliveryOffer } from '@spa-core/store/app/interfaces'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'

const scrollUp = (): void => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
    })
}

enum Style {
    DELIVERY_INFO_COLOR = 'delivery_info_color',
}

type ComponentState = {
    accountProfile: AccountProfile
    personalizedDeliveryOffers: PersonalizedDeliveryOffer
    fetchingPersonalizedDeliveryOffers: boolean
}

const resolvedPersonalizedMessage = (personalizedTownCity: string, personalizedPostCode: string) => {
    if (personalizedPostCode && !personalizedTownCity) {
        return `${t('address.postcode')} ${personalizedPostCode}`
    } else if (personalizedPostCode && personalizedTownCity) {
        return `${personalizedPostCode} ${personalizedTownCity}`
    } else {
        return t('personalized.lookup.title')
    }
}
const DeliveryInfo: React.FC = () => {
    const accountProfileStore = ({ reducers }: Store) => reducers[appReducerName].accountProfile
    const personalizedDeliveryOffersStore = ({ reducers }: Store) => reducers[appReducerName].personalizedDeliveryOffers
    const fetchingPersonalizedDeliveryOffersStore = ({ reducers }: Store) =>
        reducers[appReducerName].fetchingPersonalizedDeliveryOffers
    const selector = createSelector(
        [accountProfileStore, personalizedDeliveryOffersStore, fetchingPersonalizedDeliveryOffersStore],
        (accountProfile, personalizedDeliveryOffers, fetchingPersonalizedDeliveryOffers): ComponentState => ({
            accountProfile,
            personalizedDeliveryOffers,
            fetchingPersonalizedDeliveryOffers,
        }),
    )
    const { accountProfile, personalizedDeliveryOffers, fetchingPersonalizedDeliveryOffers }: ComponentState =
        useSelector(selector)

    const dispatch = useDispatch()

    const deliveryOfferTransporters: DeliveryOfferTransporter[] = personalizedDeliveryOffers?.transporters?.slice(0, 2)
    useEffect(() => {
        const city: string = personalizedDeliveryOffers?.city || accountProfile?.townCity
        if (city) {
            setPersonalizedTownCity(city)
        }
    }, [personalizedDeliveryOffers, accountProfile])

    const [loaded, setLoaded] = useState<boolean>(false)
    const [personalizedTownCity, setPersonalizedTownCity] = useState<string>()
    const [personalizedPostCode, setPersonalizedPostCode] = useState<string>()
    const [validPersonalizedPostCode, setValidPersonalizedPostCode] = useState<string>()
    const [openDeliveryInfoForMobile, setOpenDeliveryInfoForMobile] = useState<boolean>(false)
    const [openDeliveryInfoPopup, setOpenDeliveryInfoPopup] = useState<boolean>(false)

    useEffect(() => {
        if (!loaded) {
            dispatch(fetchPersonalizedDeliveryOptions())
            setLoaded(true)
        }
        setPersonalizedPostCode(accountProfile?.postcode?.replace(' ', ''))
    }, [accountProfile])

    const fetchDeliveryOptionsForPostalCode = useCallback((postcode: string) => {
        dispatch(fetchPersonalizedDeliveryOptions(postcode))
        setPersonalizedPostCode(postcode)
    }, [])

    useEffect(() => {
        if (!personalizedDeliveryOffers?.hasError && !fetchingPersonalizedDeliveryOffers) {
            setValidPersonalizedPostCode(personalizedPostCode)
        }
    }, [personalizedDeliveryOffers, personalizedPostCode, fetchingPersonalizedDeliveryOffers])

    const deliveryOptionObjects: React.JSX.Element[] = deliveryOfferTransporters?.map((option: DeliveryOfferTransporter) => {
        return (
            <div key={option.code} className="flex flex-1 items-center justify-start mt-4 md:mt-2 md:ml-4 self-stretch h-fit">
                <div className="e2e-ver-logo-container flex items-center justify-center w-24 flex-none">
                    <img src={option.logo} alt={option.code} />
                </div>
                <div className="pl-2">
                    {typeof option.description === 'string' ? (
                        <span dangerouslySetInnerHTML={{ __html: option.description }} />
                    ) : (
                        option.description
                    )}
                </div>
            </div>
        )
    })

    const cityAndPostalCode: string = useMemo(
        () => resolvedPersonalizedMessage(personalizedTownCity, validPersonalizedPostCode),
        [personalizedTownCity, validPersonalizedPostCode],
    )

    return (
        <div
            className={classNames(
                Style.DELIVERY_INFO_COLOR,
                'e2e-ver-postcode-lookup flex flex-col md:flex-row md:justify-between items-start md:items-center px-4 py-2 mb-1 relative',
            )}
        >
            <div className="e2e-delivery-info-greeting flex items-center">
                <div className="relative h-10 w-12">
                    <Icon className="absolute -top-px -right-0.5 font-semibold" icon={Icons.RegClock} size={32} />
                    <Icon className="absolute left-px bottom-0 font-semibold" icon={Icons.Truck} size={32} />
                </div>
                <div className="flex flex-col md:w-full ml-2">
                    <div className="text-base md:w-full ml-1">{t('personalized.transporter.text')}</div>
                    {/* Postcode selector */}
                    <div
                        onClick={() => setOpenDeliveryInfoPopup(true)}
                        className="e2e-ver-postcode-lookup-link text-sm cursor-pointer"
                    >
                        <div className="flex flex-row justify-start">
                            <Icon className="mt-1" icon={Icons.MapMarkerAlt} size={16} />
                            <span className="self-center underline font-bold ">{cityAndPostalCode}</span>
                        </div>
                    </div>
                    {/* Postcode selector popup */}
                    <DeliveryInfoPopup
                        openDeliveryInfoPopup={openDeliveryInfoPopup}
                        setOpenDeliveryInfoPopup={setOpenDeliveryInfoPopup}
                        personalizedTownCity={personalizedTownCity}
                        fetchDeliveryOptionsForPostalCode={fetchDeliveryOptionsForPostalCode}
                        personalizedPostCode={validPersonalizedPostCode}
                        scrollUp={scrollUp}
                    />
                </div>

                <Icon
                    className="e2e-ver-delivery-toggle-button md:hidden"
                    rotate90={openDeliveryInfoForMobile}
                    icon={Icons.AngleRight}
                    size={32}
                    onClick={() => setOpenDeliveryInfoForMobile(!openDeliveryInfoForMobile)}
                />
            </div>
            {/* Mobile version */}
            <div className="e2e-ver-options md:hidden">
                <SlideInOut duration={700} isOpened={openDeliveryInfoForMobile}>
                    <div className="flex flex-1 flex-col items-start">{deliveryOptionObjects}</div>
                </SlideInOut>
            </div>
            {/* Desktop version */}
            <div className="e2e-ver-options hidden md:block w-3/4">
                <div className="flex flex-1 flex-row items-start w-full">{deliveryOptionObjects}</div>
            </div>
        </div>
    )
}

export default DeliveryInfo
