import React from 'react'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    height: any
    width: any
    energyLabel: string
    energySymbol: string
    isBottomPosition?: boolean
}

export const EnergyLabel: React.FC<Props> = ({ height, width, isBottomPosition, energyLabel, energySymbol }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    return (
        // IC-12421 Enable when product info is avilable
        // <div className="flex justify-end" style={{ background: '#ebebeb' }}>
        <div className={isBottomPosition ? 'absolute bottom-0 left-[0.4rem]' : 'pt-2'}>
            <img
                src={`${sessionConfig.contextPath}/_ui/dist/static/images/energylabels/${energySymbol}_right_s.png`}
                height={height}
                width={width}
                title={energyLabel + ' ' + energySymbol}
                alt={energyLabel + ' ' + energySymbol}
            />
            {/* IC-12421 Enable when product info is avilable */}
            {/* <div className="mt-4">{strDef('phrase.energy.label', 'Produktblad')}</div> */}
        </div>
    )
}

export default EnergyLabel
