import React from 'react'
import { NavLink } from 'react-router-dom'
import EnergyLabel from '@spa-ec/displayComponents/EnergyLabel'
import Image from '@ui-elem/Image/Image'
import { getRefinedValue as filterText } from '@ui-elem/CleanText/CleanText'
import { SlimProduct } from '@spa-core/store/products/interfaces'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
type Props = {
    slimProduct: SlimProduct
    topLeftPromo?: React.ReactNode
    topRightPromo?: React.ReactNode
    searchTerm?: string
}

type ComponentState = {
    sessionConfig: SessionConfig
}

enum Style {
    IMAGE_CONFIG = 'image_config',
}

const SlimCardImageComponent: React.FC<Props> = ({ slimProduct, searchTerm, topLeftPromo = null, topRightPromo = null }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const imgSrc: string = slimProduct.imgUrl ?? `${sessionConfig.themeResourcePath}/images/placeholder.svg`
    const productUrl: string = searchTerm
        ? slimProduct.url + '?searchTerms=' + decodeURIComponent(searchTerm).toLowerCase()
        : slimProduct.url

    return (
        <div className={`relative items-center justify-between bg-white min-size-52 ${Style.IMAGE_CONFIG}`} key="cardImage">
            {/* Energy label section */}
            {slimProduct.energySymbol ? (
                <EnergyLabel
                    energyLabel={slimProduct.energyLabel}
                    energySymbol={slimProduct.energySymbol}
                    isBottomPosition={true}
                    height={'20px'}
                    width={'40px'}
                />
            ) : null}
            <NavLink to={productUrl}>
                <Image src={imgSrc} stretch={true} altText={filterText(slimProduct.title || 'image', [])} />
                {/* Product promo section */}
                {topRightPromo}
                {topLeftPromo}
            </NavLink>
        </div>
    )
}

export default SlimCardImageComponent
