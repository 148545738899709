import classNames from 'classnames'
import React from 'react'

enum Style {
    IMAGE_CONFIG = 'image_config',
}

type Props = {
    srcOnHover?: string
    srcsetOnHover?: string
    src: string
    srcset?: string
    altTextOnHover?: string
    srcsetSizes?: string
    altText?: string
    stretch?: boolean
    centered?: boolean
}

const Image: React.FC<Props> = ({
    srcOnHover,
    srcsetOnHover,
    src,
    srcset,
    altTextOnHover,
    srcsetSizes,
    altText,
    stretch = false,
    centered = false,
}) => {
    return (
        <img
            src={src}
            srcSet={srcset}
            className={classNames(
                'e2e-ver-image',
                Style.IMAGE_CONFIG,
                stretch ? 'w-full h-full' : '',
                centered ? 'block m-auto' : '',
            )}
            alt={altTextOnHover}
            sizes={srcsetSizes}
            onMouseOver={(e) => {
                if (srcOnHover && srcsetOnHover) {
                    e.currentTarget.src = srcOnHover
                    e.currentTarget.srcset = srcsetOnHover
                }
            }}
            onMouseOut={(e) => {
                if (src && srcset) {
                    e.currentTarget.src = src
                    e.currentTarget.srcset = srcset
                }
            }}
        />
    )
}

export default Image
