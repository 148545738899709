import React from 'react'
import classnames from 'classnames'
import StockStatusComponent from '../../StockStatus/StockStatus'
import { StockStatus } from '@spa-core/store/products/interfaces'

enum Styles {
    CARD_STOCK_STATUS_CONFIG = 'card_stock_status_config',
}
type Props = {
    stockStatus: StockStatus
}

const CardStockStatusComponent: React.FC<Props> = ({ stockStatus }) => {
    return (
        <>
            {stockStatus ? (
                <div className={classnames('w-full justify-center mt-2 md:mt-0', Styles.CARD_STOCK_STATUS_CONFIG)}>
                    <StockStatusComponent
                        availability={stockStatus.availability}
                        stockStatusDescription={stockStatus.stockStatusDescription}
                    />
                </div>
            ) : null}
        </>
    )
}

export default CardStockStatusComponent
